<template>
	<div class="p-vote">
		<m-loading-slider v-if="isLoading" class="p-vote__loading-slider"/>
		<div class="p-vote__tab-2" v-if="!showModal && !isLoading">

			<p-slider
				:key="voteKey"
				v-if="answersVote"
				:items="answersVote"
				category="correct"
				headlineCard
				:title="$t('vote.title')"
			/>

			<p-button
				class="p-vote__button"
				:color="'submit'"
				:text="'Abschicken'"
				:onClick="() => handleVote()"
			/>
		</div>

		<m-modal
			v-if="showModal"
			:headline="$t('vote.modal.headline')"
			:primaryActionText="$t('vote.modal.primaryText')"
			:onPrimaryAction="() => handleModalPrimary()"
			:secondaryActionText="$t('vote.modal.secondaryText')"
			:onSecondaryAction="hideModal"
		/>
	</div>
</template>

<script>
	import { AppModeEnum } from '../../../plugins/enums';
	export default {
		name: 'p-vote',
		data() {
			return {
				isLoading: true,
				answersVote: null,
				voteKey: 0
			};
		},
		computed : {
			diveStep() { return this.$store.state.dive.activeDiveStep; },
			diveIndex() { return this.$store.state.dive.diveIndex; },
			voteCorrect() { return this.$store.state.vote.voteCorrect; },
			showModal() { return this.$store.state.vote.showModal; },
			isAnonymous() { return this.$store.state.user.isAnonymous; },

			currentDive() { return this.$store.state.user.currentDive; },
			diveCount() { return this.$store.state.app.diveCount; },

			answer() { return this.$store.state.dive.answer; },

			isFinished() { return this.currentDive > this.diveCount; },

			missingVotesText() {
				// if(!this.voteCorrect && !this.voteCreative) return 'Vote 1 und Vote 2';
				if(!this.voteCorrect) return 'Vote 1 ';
				
				return 'Vote 2';
			}
		},
		created() {
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);
			this.fetchAnswers();
		},
		mounted() {
		},
		methods: {
			async fetchAnswers() {
				const { answers, correct } = await this.$getAnswerList();

				// TODO: redirect to error page
				if(!answers || !correct) this.navigateToErrorPage();

				if(this.$store.state.app.appMode === AppModeEnum.DEMO) {
					this.answersVote = [
						{ 'correct': correct},
						{
							answer: 'Eine Frau kam mit ihrem Sohn in die Filiale um ein Konto zu eröffnen. Sie sprach noch wenig Deutsch, daher hat der Sohn das meiste geregelt. Als ich ihn fragte warum er schon so gut sprechen kann, antwortete er mir, „weil er zur Schule geht“. – und dass der Sparkassen-Stiftung zu verdanken hat. Und sie jetzt deshalb hier sind.',
							countVoteCorrect : 2,
							countVoteCreative : 0,
							diveIndex : 1,
							id : 1000,
							perfectAnswer : false
						},
						{
							answer: 'Als ich selbst als Gast auf dem Beethoven-Fest in Bonn war, sprach mich eine ältere Dame an, die mich aus der Filiale kannte – und bedankte sich einfach bei mir, dass wir ihr so tolle Musik und Unterhaltung vor der Haustür ermöglichen! Sie war richtig gerührt – und das hat mich berührt.',
							countVoteCorrect : 2,
							countVoteCreative : 0,
							diveIndex : 1,
							id : 2000,
							perfectAnswer : false
						},
						{
							answer: 'Wir hatten kürzlich eine befreundete Familie zu Besuch. Da haben sie von einem Ausflug zum geologischen Lehrpfad in Bad Godesberg ge-schwärmt – und ich war ein bisschen stolz, weil ich weiß, dass wir die neue Ausschilderung unterstützt haben. In diesem Bereich machen wir so viel! Das müssen wir mehr erzählen!',
							countVoteCorrect : 2,
							countVoteCreative : 0,
							diveIndex : 1,
							id : 3000,
							perfectAnswer : false
						},
						{
							answer: 'Neulich traf ich Frederik, dessen Familie ich betreue – samstags um 13 Uhr am Kiosk. Als ich ihn fragte, ob um die Zeit nicht beim Fußball sein müsse, antwortete er, dass sein Team gesperrt wurde, da sie ohne Sponsor und Trikots die Auflagen nicht erfüllen würden. Da musste ich aktiv werden. Und jetzt ist er samstags wieder da, wo er am liebsten ist: Auf dem Platz.',
							countVoteCorrect : 2,
							countVoteCreative : 0,
							diveIndex : 1,
							id : 4000,
							perfectAnswer : false
						},
				
					];
				} else {
					this.answersVote = [{ 'correct': correct}, ...answers.slice(0,4)]; // only show 4 answers
				}
				console.log('answers', this.answersVote);
				this.isLoading = false;
			},

			async handleVote() {
				
				if(this.voteCorrect) {
					// make API Call and set states
					this.submitVotes();
					return;
				} else {
					this.$store.commit('vote/SET_SHOW_MODAL' , {showModal : true});
				}
			},

			async submitVotes() {
				if(this.voteCorrect) await this.$putVoteCorrect();

				if(this.answer !== '' && this.answer !== null) {
					// try push answers to backend
					const putAnswer = await this.putAnswer();
					if(!putAnswer) {
						this.navigateToErrorPage();
						return;
					}
				}

				// set diveIndex +1 if user navigated via navigation and not via the button from the "get ready" page
				if(this.diveIndex <= 0) {
					this.$store.commit('dive/SET_DIVE_INDEX', {diveIndex: 1});
				}

				// only use timer when user clicked on submit button to show animation
				if(!this.voteCorrect) {
					this.$store.dispatch('dive/START_NEXT_DIVE_STEP');
				} else {
					// pause to show button animation
					setTimeout(() => {
						this.$store.dispatch('dive/START_NEXT_DIVE_STEP');
						// TODO: check if this was the last dive
					}, 1200);
				}
			},
			/**
			 * push given answer to backend
			 *
			 * @returns true if request was successfull
			 */
			async putAnswer() {
				const putAnswer = await this.$putAnswer({ answer: this.answer, diveIndex: this.currentDive });

				if( putAnswer ) return true;

				return false;
			},

			async handleModalPrimary() {
				await this.submitVotes();
				// disable modal
				this.$store.commit('vote/SET_SHOW_MODAL' , {showModal : false});
			},

			hideModal() {
				this.$store.commit('vote/SET_SHOW_MODAL' , {showModal : false});
			},

			navigateToErrorPage() {
				this.$router.push('error', (err) => { console.error('Navigation error: ', err);});
			}
		}
	};
</script>
