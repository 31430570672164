export default {
	lorem: {
		title: 'Lorem Ipsum next Page',
		field: {
			placeholder: 'Nennt mich einfach',
			type: 'test'
		},
		submitText: 'Thats me'
	},
	sideNavigation: {
		explore: 'Explore',
		dive: 'dive',
		getReady: 'Get Ready'
	},
	login: {
		email: {
			subline: 'Persönlicher Zugang',
			headline: 'Was ist deine Mail-Adresse?',
			error: 'Bitte @denkwerk Domain verwenden!',
			button:'Abschicken',
			fieldEmail: {
				placeholder: 'Geschäftliche Mail-Adresse'
			}
		},
		language: {
			headline: 'Please choose a <span class="m-headline-card__highlight">language</span>',
			button: 'Bestätigen',
		},
		confirm: {
			headline: 'Super, dein Link ist unterwegs!',
			text: 'Wir haben deinen persönlichen <b><i>Teilnehmerlink</i></b> an deine Mail gesendet.<br/><br/> Bitte sieh in deinem <b><i>Mail-Postfach</i></b> nach und benutze deinen <b><i>Link</i></b>, um am Dive teilzunehmen.<br/><br/> Bis gleich!',
		}
	},
	firstLaunch: {
		intro: {
			headline: 'Willkommen!',
			copy: 'Gemeinsam tauchen wir in die <strong><i>congstar Experience</i></strong> ein. Schön, dass du dabei bist!',
			button:'3..2..1..los!'
		},
		yourName: {
			headline: 'Wie heißt du?',
			subline: 'First Things First',
			fieldFirstName: {
				placeholder: 'Vorname',
				name: 'firstName',
				type: 'name',
			},
			fieldLastName: {
				placeholder: 'Nachname',
				name: 'lastName',
				type: 'name'
			},
			submitButton: 'That\'s me!'
		}
	},
	ready: {
		headline: 'Hey, ',
		copy: 'Willkommen zum <strong><i></i>Dive</i></strong></strong> – unserer Lernplattform, um den Dingen auf den Grund zu gehen.<br><br>Alles dreht sich dabei um Fragen, auf die es keine einfachen Antworten gibt – sondern ganz viele, unterschiedliche,  persönliche, wertvolle, kreative, spannende, ...',
		linkText: 'hier.',
		linkHref: 'https://share.denkwerk.com/s/Ddzr7MjAAASeYEn',
		button: 'Los Geht\'s'
	},
	dive: {
		introFTU: {
			headline: 'Tauchen wir ab!',
			copy1: 'Wir haben einen exemplarischen Tauchgang entwickelt, der zeigt und erlebbar macht, wie der Dive dabei helfen kann, unsere neue Strategie zum Leben zu erwecken und in die Organisation zu tragen',
			copy2: '',
			button: 'Ab in\'s Wasser!'
		},
		introWelcomeBack: {
			headline: 'Willkommen zurück!',
			copy1: 'Du bist in Dive',
			button: 'Dive fortsetzen'
		},
		introComplete: {
			headline: 'Let\'s talk!',
			copy1: '',
			copy2: '',
			copy3: 'Ein Prinzip, unbegrenzte Möglichkeiten: Der Dive schafft einen einzigartigen Blick aufs Ganze, durch die Perspektiven aller. Und schafft ein bleibendes Ergebnis, das nachhaltig Wirkung entfalten  kann.',
			linkText: '',
			linkHref: 'https://denkwerk.com',
			button: 'Explore'
		},
		tabNav: {
			szenario: 'Szenario',
			example: 'Beispiel',
			consideration: 'Überlegung',
			action: 'Action'
		},
		buttons: {
			szenario: 'Let\'s Go!',
			example: 'Spannend!',
			consideratio: 'Verstanden!',
			actionSkip: 'Auslassen',
			actionSend: 'Abschicken',
		},
		skipModal: {
			headline: 'Willst du es nicht doch mal probieren?',
			copy: 'Ein Tipp gegen die Blockade: Schreib zuerst ein paar Stichwörter auf und bilde erst im Anschluss die passenden Sätze daraus.',
			secondaryButton: 'Zurück',
			primaryButton: 'Überspringen'
		},
		emptyModal: {
			headline: 'Ups, deine Antwort ist noch leer. War das Absicht?',
			secondaryButton: 'Zurück',
			primaryButton: 'Überspringen'
		},
		anonymousModal: {
			headline: 'Was für tolle Ergebnisse!',
			copy: 'Bist du einverstanden, dass deine Antworten mit Namen auch nach Ablauf des XDives intern verwendet werden (z.B. Wiki, interne Präsentationen)?',
			secondaryButton: 'Bitte nur anonym',
			primaryButton: 'Ja, gerne'
		},
		explore: {
			title: 'HIGHLIGHTS',
			modalButton: 'nächster Dive',
			modalButtonLastDive: 'XDIVE ABSCHLIEßEN',
			modalBody: [
				'Super Beiträge! Klar, congstar ist <span class="m-headline-card__highlight">"bunt"</span> - aber auch noch so viel mehr...',
				'Zeilenweise <span class="m-headline-card__highlight">Leidenschaft!<span class="m-headline-card__highlight">',
				'Chapeaux! Bei so viel <span class="m-headline-card__highlight">Kreativität</span> beißt bestimmt jemand an...'
			],
			noUserAnswer: 'Du hast hier keine Antwort abgegeben.'
		}
	},
	vote: {
		title: 'EINSCHÄTZUNG',
		cardHeadline: 'Welche der Antworten ist',
		modal: {
			headline: 'Oh, möchtest du hier wirklich keine Antwort auszeichnen?',
			copy1: 'Du hast für ',
			copy2: 'noch keine Stimme abgegeben. War das Absicht?',
			primaryText: 'Skippen',
			secondaryText: 'Zurück'
		}
	},
	userMenu: {
		timerLabel: 'Tauchzeit <br> Empfehlung'
	},
	explore: {
		intro: {
			headline: 'Diver’s high!',
			copy: ' Fragen - unzählige kreative Gedanken und starke Antworten: Hier findest du die <strong><i>Schätze der Dives</i></strong> nochmal gesammelt an einem Ort.',
			button: 'Los geht\'s'
		},
		tabNavTitle: 'Dive',
		answerCard: {
			comment: 'Kommentar',
			bestVoteCreative: {
				icon: 'Creative',
				title: 'Euer Kreativ-Pick'
			},
			bestVoteCorrect: {
				icon: 'Correct',
				title: 'Euer congstar Pick'
			},
			perfectAnswer: {
				icon: 'Snorkler-flag',
				title: 'Tauch-Buddies'
			},
			ceoPick: {
				icon: 'CeoPick-30px',
				title: 'POP\'s Pick'
			},
			learning: {
				icon: 'Learning-30px',
				title: 'Besonders spannend'
			},
			fun: {
				icon: 'FunnyAnswer-30px',
				title: 'Besonders spaßig'
			},
			short: {
				icon: 'ShortestAnswer-30px',
				title: 'Kürzeste Antwort'
			},
			userAnswer: {
				icon: 'User',
				title: 'Deine Antwort'
			}
		},
		noUserAnswer: 'Du hast hier keine Antwort abgegeben.'
	},
	error: {
		pageNotFound: {
			img: '',
			headline: '404',
			copy: 'Huch – sieht ganz so aus, als hättest du dich vertaucht! <br><br> Hier unten existiert so gut wie nichts mehr. Noch nicht mal die gesuchte Seite.',
			button: 'Zurück zum Dive'
		},
		notAuthorized: {
			img: '',
			headline: 'Ups, ein blinder Passagier!',
			copy: 'Sorry, aber dieser <strong><i>Link ist ungültig</i></strong> oder du bist über ein anderes Gerät angemeldet.<br><br>Um am Dive teilzunehmen, benutze bitte den <strong><i>Link</i></strong>, den du von uns <strong><i>per Mail</i></strong> bekommen hast.<br><br>Bis gleich!',
		}
	}
};
