<template>
	<div
		id="app">
		<div
			id="vue-scroll-container"
			ref="scrollContainer"
			:class="'app-container'"
		>
			<p-loading v-if="isLoading"></p-loading>
			<!-- <m-dev-nav /> -->
			<m-header />

			<m-grid hasSlot :class="{'m-grid--no-overflow' : isSliderOverflow}" v-if="getUserData">
				<div class="column-1">
					<p-side-nav v-if="hideNav && isFirstTime" />
				</div>
				<div class="offset-3-column-11">
					<router-view/>
				</div>
			</m-grid>
		</div>
	</div>
</template>

<style lang="scss">
	@import '~@/assets/styles/main.scss';
</style>

<script>
	import PSideNav from '@/components/pattern/layout/p-side-nav';
	import { AppModeEnum } from './plugins/enums';

	export default {
		components: {
			PSideNav
		},
		data() {
			return {
				AppModeEnum: AppModeEnum,
				url: null,
				urlAuthToken: null,
				storageAuthToken: null,
				storageClientId: null,
			};
		},
		computed: {
			isLoading() { return this.$store.state.app.isLoading; },
			isFirstTime() { return !this.$store.state.app.isFirstTime; },
			hideNav() { return !this.$store.state.app.hideNavigation; },
			diveStep() { return this.$store.state.dive.activeDiveStep; },
			isSliderOverflow() { return  this.$store.state.app.isSliderOverflow; },

			currentDive() { return this.$store.state.user.currentDive; },
			firstName() { return this.$store.state.user.firstName; },
			diveCount() { return this.$store.state.app.diveCount; },
			exploreOpening() { return this.$store.state.app.exploreOpening; },
			unlockedLevel() {return this.$store.state.app.unlockedLevel; },

			apiError() { return this.$store.state.app.apiError;}
		},
		async created() {
			this.$store.commit('app/SET_IS_LOADING', { isLoading: true });
			this.$store.commit('app/SET_APPMODE', { appMode: AppModeEnum.DEMO});

			this.url = new URL(window.location.href);

			this.urlAuthToken = this.url.searchParams.get('authToken');
			this.storageAuthToken = localStorage.getItem('token');
			this.storageClientId = localStorage.getItem('clientId');

			if(this.urlAuthToken) {
				await this.loginUser();
			} else if(!this.urlAuthToken && this.storageAuthToken && this.storageClientId) {
				console.log('...get dive Count');
				await this.getDiveCount();
				console.log('...get user data');
				await this.getUserData();
			} else {
				console.log('weder token in URL noch local');
				this.$store.commit('app/SET_IS_LOADING', { isLoading: false });
				this.$router.push('setup', (err) => { console.error('Navigation error: ', err);});
			}

			//this.unlockExplore(); //TODO: only check here or in api
		},
		methods: {
			/**
			 * Try login user
			 */
			async loginUser() {

				await this.createClientId();
				const clientId = localStorage.getItem('clientId');

				const login = await this.$getAuthToken(this.urlAuthToken, clientId);

				// login success, try get userdata
				if(login) {
					await this.getDiveCount();
					await this.getUserData();
					return;

				} else {
					console.log('noLogin');
					// delete clientID & token and push to errorpage
					localStorage.removeItem('clientId');
					localStorage.removeItem('token');

					this.$router.push('error', (err) => { console.error('Navigation error: ', err);});
				}
			},

			/**
			 * Try get userdata from API
			 */
			async getUserData() {

				// get user data if defined
				const getUserData = await this.$getUser();

				if(getUserData) {
					this.$i18n.locale = 'de'; // you can change this to the user language if the language should be picked during login

					// wenn kein Name
					// --> Namenseingabe
					if (this.firstName) { // user has signed up before

						if (this.currentDive <= this.diveCount) {
							this.$store.commit('app/SET_IS_FIRSTTIME', { isFirstTime: false });
							this.$store.commit('app/SET_IS_LOADING', { isLoading: false });

							const activeLevel = this.currentDive === 0 ? 0 : 1; // start at the "ready" or the "dive" level
							
							if (activeLevel < 1) {
								this.$store.commit('app/SET_ACTIVE_LEVEL' , {activeLevel : 0});
								this.$router.push('ready', (err) => { console.error('Navigation error: ', err);});

							} else {
								this.$store.commit('app/SET_ACTIVE_LEVEL' , {activeLevel : 1});
								this.$router.push('dive', (err) => { console.error('Navigation error: ', err);});
							}
						} else if (this.currentDive > this.diveCount) {
							this.$router.push('explore', (err) => { console.error('Navigation error: ', err);});
							this.$store.commit('app/SET_IS_LOADING', { isLoading: false });
						} else {
							this.$store.commit('app/SET_IS_LOADING', { isLoading: false });
							this.$router.push('error', (err) => { console.error('Navigation error: ', err);});
						}

					} else { // user has to sign up
						this.$store.commit('app/SET_IS_LOADING', { isLoading: false });
						this.$router.push('setup', (err) => { console.error('Navigation error: ', err);});
					}
				} else {
					this.$router.push('error', (err) => { console.error('Navigation error: ', err);});
				}

			},

			async getDiveCount() {
				await this.$getDiveCount();
			},

			/**
			 * create a hash to use as client id for login at server
			 * save clientId in localStorage
			 */
			async createClientId() {
				let hash = '';
				let letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

				for (let i = 0; i < 32; i++) {
					hash += letters.charAt(Math.floor(Math.random() * letters.length));
				}

				localStorage.clientId = hash;
			},

			/**
			 * Unlock Explore Level if explore
			 */
			unlockExplore() {
				const today = new Date();
				const exploreOpenDate =  new Date(this.exploreOpening);
				if(today.getTime() >= exploreOpenDate.getTime()) {
					this.$store.commit('app/SET_UNLOCKED_LEVEL', { unlockedLevel: 2 });
				} else {
					return;
				}
			}
		}
	};
</script>
