<template>

	<div class="p-explore-dive">
		<transition name="slide-p-horizontal" mode="out-in">

			<m-loading-slider v-if="isLoading"/>
			<m-swiper :key="'dive-slider-' + activeTab" v-if="!isLoading && sliderItems">

				<template v-slot:slider-items>

					<template v-for="(item, index) in sliderItems">

						<template v-if="index <= slidesToShow">
							<swiper-slide
								v-if="item.isTwoRows"
								class="p-card__slider"
								:key="'answer-' + index"
							>
								<m-answer-card
									:data="item.firstItem"
								/>

								<m-answer-card
									:data="item.secondItem"
								/>
							</swiper-slide>

							<swiper-slide
								v-else
								class="p-card__slider"
								:key="'answer-' + index"
							>
								<m-answer-card
									:data="item.data"
								/>
							</swiper-slide>

						</template>


					</template>

				</template>

			</m-swiper>
		</transition>


	</div>

</template>

<script>
	import {eventBus} from '@/main';

	export default {
		name: 'p-explore-dive',
		data() {
			return {
				isLoading: true,
				isMobileScreen: false,
				slidesToShow: 10,

				// prepared array with objects, ready to use with answer card
				answerCardData: null,
				// endresult of all answers, ready to use in template
				sliderItems: null,

				currentDive: 1,
				answersOfCurrentDive: null,
				titleOfCurrentDive: null,
			};
		},
		computed: {
			activeTab() {
				return this.$store.state.explore.activeTab;
			},
			diveCount() {
				return this.$store.state.app.diveCount;
			}
		},
		created() {
			window.addEventListener('resize', this.onWindowResize);
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);

			/**
			 * Register listener on 'changeExploreDive' event
			 * To trigger this event in other component emit 'changeExploreDive'
			 *
			 * Example:
			 * import { eventBus } from '@/main'
			 *
			 * onClick() {eventBus.$emit('changeExploreDive');}
			 */
			eventBus.$on('changeExploreDive', async (diveToShow) => {
				this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);
				this.currentDive = diveToShow;
				await this.getAnswersOfCurrenDive();
				await this.getTitleOfCurrenDive();
				this.prepareSliderItems();
				this.isLoading = false;	
			});
		},
		async mounted() {
			await this.getAnswersOfCurrenDive();
			await this.getTitleOfCurrenDive();
			this.prepareSliderItems();
			this.isLoading = false;
		},
		async beforeDestroy() {
			// deregister event from eventbus before component was destroyed
			eventBus.$off('changeExploreDive');
		},
		destroyed() {
			window.removeEventListener('resize', this.onWindowResize);
		},
		methods: {
			onWindowResize() {
				if (window.innerWidth <= 1024) {
					this.isMobileScreen = true;
				} else {
					this.isMobileScreen = false;
				}
			},

			async getAnswersOfCurrenDive() {
				this.isLoading = true;
				const answersOfCurrentDive = await this.$getExploreAnswers(this.activeTab);

				if(answersOfCurrentDive) {
					this.answersOfCurrentDive = answersOfCurrentDive;
				} else {
					console.error('Keine Antworten erhalten');
				}
			},
			async getTitleOfCurrenDive() {
				this.isLoading = true;
				const titleOfCurrentDive = await this.$getExploreTitle(this.activeTab);

				if(titleOfCurrentDive) {
					this.titleOfCurrentDive = titleOfCurrentDive;
				} else {
					console.error('Keinen Titel erhalten');
				}
			},
			prepareSliderItems() {
				// prepare data for title card
				const title = this.titleOfCurrentDive.title;

				const titleCardData = {
					type: 'title',
					title: title
				};

				// prepare data for answers cards
				const perferctAnswers = this.answersOfCurrentDive.perfectAnswer.map(answer => {
					return {
						type: null,
						category: null,
						id: answer.id,
						answer: answer.answer,
						userName: null,
						author: null
					};
				});
				const firstPartPerfectAnswers = perferctAnswers.slice(0, 3);
				const secondPartPerfectAnswers = perferctAnswers.slice(3);

				// prepare data for user card
				const userAnswerCardData = {
					type: 'userAnswer',
					category: 'userAnswer',
					id: 'useranswer-' + this.currentDive,
					answer: this.answersOfCurrentDive.userAnswer ?? this.$t('explore.noUserAnswer'),
					userName: null,
					author: null
				};

				this.makeSliderItemObject([titleCardData, ...firstPartPerfectAnswers, userAnswerCardData, ...secondPartPerfectAnswers]);
			},
			makeSliderItemObject(items) {
				const sliderItems = items
					.map((item, index, array) => {
						// headline Card should be one row
						if(index === 0) {
							return {
								isTwoRows: false,
								data: item
							};
						}

						if (!!item) {
							// get next item to be able to merge it with current later
							const nextItem = array[index + 1] ?? null;
							console.log('nextitem ', nextItem);

							// merge current and next item if no comment is set
							if (nextItem) {

								// set new key to be able to
								const mergedItem = {
									isTwoRows: true,
									firstItem: item,
									secondItem: nextItem
								};

								// splice next item to prevent duplication
								array.splice(index + 1, 1);

								return mergedItem;
							} else {
								return {
									isTwoRows: false,
									data: item
								};
							}
						}
					})
					// filter empty elements as result of mapping
					.filter(element => !!element);
				this.sliderItems = sliderItems;
			}
		}
	};
</script>
