<template>
	<div class="p-dive-explore">
		<m-loading-slider v-if="isLoading" class="p-dive-explore__loading-slider"/>

		<m-swiper :key="'dive-explore'" :title="$t('dive.explore.title')" v-if="!isLoading && sliderItems">
			<template v-slot:slider-items>

				<template v-for="(item, index) in sliderItems">

					<swiper-slide
						v-if="item.isTwoRows"
						class="p-card__slider"
						:key="'answer-' + index"
					>
						<m-answer-card
							:data="item.firstItem"
						/>

						<m-answer-card
							:data="item.secondItem"
						/>
					</swiper-slide>

					<swiper-slide
						v-else
						class="p-card__slider"
						:key="'answer-' + index"
					>
						<m-answer-card
							:data="item.data"
						/>
					</swiper-slide>

					<swiper-slide
						v-if="index === sliderItems.length - 1"
						class="p-card__slider p-card__slider--modal"
						:key="'modal-' + index + 1"
					>
						<m-modal
							:gridPositioning="false"

							:headline="getEndcardText()"
							:primaryActionText="currentDive === diveCount ? $t('dive.explore.modalButtonLastDive') : $t('dive.explore.modalButton')"
							:onPrimaryAction="() => updateUserAndStartNewDive()"
						/>
					</swiper-slide>
				</template>

			</template>
		</m-swiper>
	</div>
</template>

<script>
	export default {
		name: 'p-dive-explore',
		data() {
			return {
				isLoading: true,
				isMobileScreen: false,
				slidesToShow: 5,

				answersOfCurrentDive: null,
				titleOfCurrentDive: null,
				sliderItems: null,
			};
		},
		computed: {
			currentDive() { return this.$store.state.user.currentDive; },
			diveCount() { return this.$store.state.app.diveCount; },
		},
		created() {
			window.addEventListener('resize', this.onWindowResize);
			this.$store.commit('app/SET_IS_SLIDEROVERFLOW', true);
		},
		async mounted() {
			await this.getAnswersOfCurrenDive();
			await this.getTitleOfCurrenDive();
			this.prepareSliderItems();
			this.isLoading = false;
		},
		destroyed() {
			window.removeEventListener('resize', this.onWindowResize);
		},
		methods: {
			onWindowResize() {
				if (window.innerWidth <= 1024) {
					this.isMobileScreen = true;
				} else {
					this.isMobileScreen = false;
				}
			},
			handleButtonClick() {
				this.$store.commit('dive/START_NEW_DIVE');
			},
			async getAnswersOfCurrenDive() {
				this.isLoading = true;
				const answersOfCurrentDive = await this.$getExploreAnswers(this.currentDive);

				if(answersOfCurrentDive) {
					this.answersOfCurrentDive = answersOfCurrentDive;
				} else {
					console.err('Keine Antworten erhalten');
				}
			},
			async getTitleOfCurrenDive() {
				this.isLoading = true;
				const titleOfCurrentDive = await this.$getExploreTitle(this.currentDive);

				if(titleOfCurrentDive) {
					this.titleOfCurrentDive = titleOfCurrentDive;
				} else {
					console.err('Keinen Titel erhalten');
				}
			},
			prepareSliderItems() {
				// prepare data for title card
				const splittedHEadline = this.titleOfCurrentDive.headline.split(/[__]/);
				const headlineFirstPart = splittedHEadline[0];
				const headlineBluePart = splittedHEadline[1];
				const headlineLastPart = splittedHEadline[2];

				const titleCardData = {
					type: 'title',
					title: `${headlineFirstPart} <span class="m-headline-card__highlight">${headlineBluePart}</span>${headlineLastPart}`
				};
				console.log(titleCardData);

				// prepare data for answers cards
				const perferctAnswers = this.answersOfCurrentDive.perfectAnswer.map(answer => {
					return {
						type: null,
						category: null,
						id: answer.id,
						answer: answer.answer,
						userName: null,
						author: null
					};
				});
				const firstPartPerfectAnswers = perferctAnswers.slice(0, 3);
				const secondPartPerfectAnswers = perferctAnswers.slice(3);

				// prepare data for user card
				const userAnswerCardData = {
					type: 'userAnswer',
					category: 'userAnswer',
					id: 'useranswer-' + this.currentDive,
					answer: this.answersOfCurrentDive.userAnswer ?? this.$t('explore.noUserAnswer'),
					userName: null,
					author: null
				};

				this.makeSliderItemObject([titleCardData, ...firstPartPerfectAnswers, userAnswerCardData, ...secondPartPerfectAnswers]);
			},
			makeSliderItemObject(items) {
				const sliderItems = items
					.map((item, index, array) => {
						// headline Card should be one row
						if(index === 0) {
							return {
								isTwoRows: false,
								data: item
							};
						}

						if (!!item) {
							// get next item to be able to merge it with current later
							const nextItem = array[index + 1] ?? null;

							// merge current and next item if no comment is set
							if (nextItem) {

								// set new key to be able to
								const mergedItem = {
									isTwoRows: true,
									firstItem: item,
									secondItem: nextItem
								};

								// splice next item to prevent duplication
								array.splice(index + 1, 1);

								return mergedItem;
							} else {
								return {
									isTwoRows: false,
									data: item
								};
							}
						}
					})
					// filter empty elements as result of mapping
					.filter(element => !!element);

				this.sliderItems = sliderItems;
			},
			getEndcardText() {
				return this.$t(`dive.explore.modalBody[${this.currentDive - 1}]`);
			},
			/**
			 * Update currentDive in backend
			 *
			 * @returns true if request was successfull
			 */
			async updateUserAndStartNewDive() {
				this.$store.commit('app/SET_IS_LOADING', { isLoading: true });
				const user = {
					firstName: this.$store.state.user.firstName,
					lastName: this.$store.state.user.lastName,
					currentDive: this.currentDive + 1, //user will start with next dive if he quits after this one
					isAnonymous: true
				};

				const putUser = await this.$putUser(user);

				if(!putUser) {
					this.$store.commit('app/SET_IS_LOADING', { isLoading: false });
					this.navigateToErrorPage();
					return;
				}

				this.$store.commit('app/SET_IS_LOADING', { isLoading: false });
				this.$store.dispatch('dive/START_NEXT_DIVE_STEP');
			},
			navigateToErrorPage() {
				this.$router.push('error', (err) => { console.error('Navigation error: ', err);});
			}
		}
	};
</script>