<template>
	<div
		class="m-header grid-container"
	>
		<div class="column-1 m-header__col">
			<!--p-mobile-menue v-if="isMobile && hideNav && isFirstTime" /!-->
			<img v-if="!isMobile || (isMobile && (!hideNav || !isFirstTime))" class="m-header__brand" src="@/assets/images/splash/spk-logo-druck.png" :alt="'brand logo ' + brandName">
		</div>
		<div class="offset-11-column-13">
			<p-user-menu v-if="hideNav && isFirstTime"/>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'm-header',
		data() {
			return {
				windowWidth: 0,
				mobileMaxSize: 1023
			};
		},
		created: function() {
			window.addEventListener('resize', this.handleResize);
			this.handleResize();
		},
		destroyed: function() {
			window.removeEventListener('resize', this.handleResize);
		},
		computed: {
			isMobile() {
				return this.windowWidth <= this.mobileMaxSize;
			},
			isFirstTime() { return !this.$store.state.app.isFirstTime; },
			brandName() { return 'Congstar';}, // TODO: replace with fetched information
			hideNav() { return !this.$store.state.app.hideNavigation; },

		},
		methods: {
			handleResize() {
				this.windowWidth = window.innerWidth;
			}
		}
	};
</script>
