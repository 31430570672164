<template>
	<div
		class="m-intro"
	>
		<m-grid hasSlot>
			<div class="column-6">
				<img
					v-if="img"
					class="resized-img"
					:src="require('@/assets/images/m-intro/' + img)"
				/>
			</div>

			<div class="column-6">
				<div class="flex">
					<p-headline
						v-if="headline" :text="headline" border h1
					/>
					<p-text
						v-if="copy"
						:text="copy"
						:linkText="copyLinkText && copyLinkText"
						:linkHref="copyLinkHref && copyLinkHref"
					/>
					<slot />
					<m-grid v-if="button">
						<p-button
							:text="button"
							color="fillup"
							:onClick="handleButtonClick"
							class="m-intro__button"
						/>
					</m-grid>
				</div>
			</div>
		</m-grid>
	</div>
</template>

<script>
	export default {
		name: 'm-intro',
		props: {
			headline: {
				type: String,
				required: true,
			},
			noOverflow: {
				type: Boolean,
				required: false,
				default: false
			},
			copy: {
				type: String,
				required: false,
			},
			copyLinkText: {
				type: String,
				required: false,
			},
			copyLinkHref: {
				type: String,
				required: false,
			},
			button: {
				type: String,
				required: true,
			},
			img: {
				type: String,
				required: true,
			},
			handleButtonClick: {
				type: Function,
				required: true
			}
		},
	};
</script>
