<template>
	<m-intro
		img="DiveOutro.svg"
		:headline="$t('dive.introComplete.headline')"
		:copy="!isDemoMode && $t('dive.introComplete.copy1') + '<strong><i>' + readableDiveCount + ' Dives</i></strong> ' + $t('dive.introComplete.copy2')"
		:copyLinkText="$t('dive.introComplete.linkText')"
		:copyLinkHref="$t('dive.introComplete.linkHref')"
	>
		<p-text :text="$t('dive.introComplete.copy3')"></p-text>
	</m-intro>
</template>

<script>
	import { AppModeEnum } from '../../../../plugins/enums';

	export default {
		name: 'p-dive-intro-complete',
		data() {
			return {
				readableDiveCount: ''
			};
		},
		computed: {
			isDemoMode() {return this.$store.state.app.appMode === AppModeEnum.DEMO; },
			diveCount() { return this.$store.state.app.diveCount; },
		},
		mounted() {
			this.setReadableDiveCount(this.diveCount);
		},
		methods: {
			setReadableDiveCount(diveCount) {
				const words = ['null', 'eins', 'zwei', 'drei', 'vier', 'fünf', 'sechs', 'sieben', 'acht', 'neun'];
				this.readableDiveCount = words[diveCount];
			},
			navigateToExplore() {
				this.$store.commit('app/SET_UNLOCKED_LEVEL' , {unlockedLevel : 2});
				this.$store.commit('app/SET_ACTIVE_LEVEL' , {activeLevel : 2});
				this.$router.push('explore', (err) => { console.error('Navigation error: ', err);});
			},
		}
	};
</script>
