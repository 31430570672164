<template>
	<div class="m-slideshow__content m-headline-card">
		<p>
			{{$t('vote.cardHeadline')}} <span class="m-headline-card__highlight">auch gut</span>?
		</p>
	</div>
</template>

<script>
	export default {
		name: 'm-headline-card',
		components: {
		},
		props: {
			text: {
				type: String,
				required: true,
			},
		}
	};
</script>
